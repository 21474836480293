@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.loader {
  .ant-modal-content {
    display: contents !important;
  }

  .ant-modal-body {
    display: flex !important;
    justify-content: center !important;
  }
}

.Container {
  padding: 20px !important;
  background-color: #fff;
  border-radius: 20px !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
    0px 0px 8px 0px rgba(0, 0, 0, 0.04);
}

.maindiv {
  overflow-x: auto;
}

.ant-table-content {
  .ant-table-measure-row {
    display: none !important;
  }

  table {
    table-layout: auto !important;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    border: 1px solid #d9dde6;
    margin-top: 1rem;

    thead {
      border: 1px solid #171624 !important;
    }

    th {
      font-size: 16px;
      line-height: 16px;
      font-style: normal;
      font-weight: 600 !important;
      color: #333 !important;
      background-color: #f1f5ff !important;
      border-right: 1px solid #e9e7f5;
    }

    tr {
      border: 1px black;
    }

    td {
      font-size: 16px;
      line-height: 22px;
      font-style: normal;
      font-weight: 500;
      border-right: 1px solid #e9e7f5;
      color: #333;
      background-color: #fff !important;
      text-align: left;
      padding: 15px !important;
    }
  }
}

.filterContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.filter-field {
  opacity: 100;
  transition: opacity 0.3s;
}

/* .filter {
  margin-top: 8px !important;
} */

.placeholder {
  color: #0a0033;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.filterbtn {
  background-color: #f6f6f7 !important;
  border-radius: 4px !important;
  border: 1px solid #c9cccf !important;
  color: #0a0033 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  height: auto;
}

.goBtn {
  width: max-content;
  border: 1px solid #199fda !important;
  border-radius: 4px !important;
  height: auto !important;
  color: #199fda !important;
  font-weight: 500;
}

.clearBtn {
  border-radius: 4px !important;
  height: auto !important;
  border-color: #d9d9d9 !important;
  color: #999999 !important;
}

.mainContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.secondaryContainer {
  position: relative;
  display: flex;
  align-items: center;

  .ant-input-affix-wrapper {
    height: 36px;
    padding: 5px;

    .ant-input {
      width: 100%;
      font-size: 16px;
      outline: none;
      height: auto;
    }

    .ant-input:focus {
      color: #333;

      border-color: #0069d9;
    }
  }
}

/* Export Container */
.exportContainer {
  display: flex;
  margin-left: 10px;

  .commonButton {
    height: 36px;
    background-color: #59bf87 !important;

    &:hover {
      background-color: #59bf87 !important;
    }
  }
}

.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: end;
}

.cancleBtnViewWidth {
  border-radius: 9999px;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 50%;
}

.cancleBtnwidth {
  border-radius: 9999px;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
}

.saveBtn {
  width: 100% !important;
  border-radius: 9999px;
  background-color: #1677ff;
  margin-top: 4px;
  margin-bottom: 4px;
}

.cancleBtnAction {
  width: 100%;
  border-radius: 9999px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.saveBtnAction {
  width: 100%;
  background-color: rgb(239 68 68);
  border-radius: 9999px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.saveBtnAction:hover {
  background-color: rgb(248 113 113) !important;
}

.popupMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.viewUser,
.editUser,
.deleteUser {
  width: calc(100% - 20px);
  font-size: 14px;
  line-height: 39px;
  color: #666666;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}

.viewUser {
  border-top-left-radius: 1rem;
  /* 16px */
  border-top-right-radius: 1rem;
  /* 16px */
  border-bottom: 1px solid #d7d8e4;
}

.viewUser:hover {
  background-color: #0ea5e9;
  color: #ffffff;
}

.editUser {
  border-bottom: 1px solid #d7d8e4;
}

.editUser:hover {
  background-color: #0ea5e9;
  color: #ffffff;
}

.deleteUser {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.deleteUser:hover {
  background-color: #0ea5e9;
  color: #ffffff;
}

.selectContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.custom-filter-dropdown {
  height: auto;

  .ant-select-selector {
    border-radius: 4px;
    border: 1px solid #d9d9d9 !important;
  }
}

.custom-date-range-picker {
  border-radius: 4px;
  color: #000;
  border: 1px solid #d9d9d9 !important;

  .ant-picker-input {
    color: #000;

    input::placeholder {
      color: #000;
    }
  }
}

.custom-avatar {
  background: #d3d3d3;
  border-radius: 8px;
}

.slidebar {
  width: 20%;
}

.mainTitledefaultClass {
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  font-family: "poppins";
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.descriptiondefaultClass {
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-family: "poppins";
  color: rgb(107, 114, 128);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.cancelBtndefautClass {
  width: 100%;
  border-radius: 9999px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.saveBtndefautClass {
  width: 100%;
  border-radius: 9999px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: rgb(239, 68, 68) !important;
}

.saveBtndefautClass:hover {
  background-color: rgb(239, 68, 68) !important;
}

.assignNutritionist {
  display: flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: start !important;
}

.gradient-range {
  position: relative;
  width: 100%;
  height: 40px;
}

.labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.current-value {
  position: absolute;
  top: -45px;
  padding: 2px 5px;
}

.rangeBorderContainer {
  padding: 10px;
  max-width: 100%;
  margin: auto;
}

.h2ScoreRangeText {
  font-size: 24px;
  font-weight: bold;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.scoreTypeText {
  color: #333;
  margin: 0 5px;
}

.colorRangeContainer {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.indicter {
  display: flex;
}

.colorRangeItem {
  flex: 1;
}

.colorRangeView {
  height: 9.52px;
}

.scoreRangeView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.scoreTypeText {
  font-size: 12px;
}
